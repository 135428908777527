
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { updateUser } from '@/api/user/userApi';
import AtomActionSelector from '@/components/atoms/AtomActionSelector.vue';
import AtomText from '@/components/atoms/AtomText.vue';
import { toast, ToastVariants } from '@/components/atoms/AtomToast';
import OrganismPageSubHeader from '@/components/organisms/page-header/OrganismPageSubHeader.vue';
import OrganismRideSettingsDriver from '@/components/organisms/settings/OrganismRideSettingsDriver.vue';
import OrganismRideSettingsPassenger from '@/components/organisms/settings/OrganismRideSettingsPassenger.vue';
import RolesEnum from '@/enums/rides/RolesEnum';
import OrganismGeneralRideSettings from '@/components/organisms/settings/OrganismGeneralRideSettings.vue';

enum TabName {
  GENERAL = 'general',
  DRIVER = 'driver',
  PASSENGER = 'passenger'
}

export default Vue.extend({
  name: 'TemplateSettingsRides',
  components: {
    AtomActionSelector,
    AtomText,
    OrganismPageSubHeader,
    OrganismRideSettingsDriver,
    OrganismRideSettingsPassenger,
    OrganismGeneralRideSettings,
  },
  data: () => ({
    TabName,
    activeTab: TabName.GENERAL,
    role: RolesEnum.DRIVER_AND_PASSENGER,
    matchingGroup: [] as Array<number>,
    isLoading: false,
  }),
  computed: {
    ...mapGetters('car', ['getAllCars']),
    ...mapGetters('user', [
      'getUserId',
      'firstName',
      'lastName',
      'displayName',
      'phoneNumber',
      'rideSettings',
      'sms',
    ]),
  },
  methods: {
    ...mapActions('user', ['fetchUser', 'setUserDecodedImage']),
    ...mapActions('car', ['fetchAllCars']),
    switchTab(tab: TabName) {
      this.activeTab = tab;
    },
    async finish(updatedSettings: object) {
      this.isLoading = true;
      const { status } = await updateUser({
        userId: this.getUserId,
        firstName: this.firstName,
        lastName: this.lastName,
        displayName: this.displayName,
        rideSettings: {
          ...this.rideSettings,
          ...updatedSettings,
        },
        sms: {
          detail: this.phoneNumber,
          usageTypes: this.sms.usageTypes,
        },
      });

      if (status === 200) {
        await this.fetchUser(this.getUserId);
        toast(this.$bvToast, this.$t('settings.rides.messages.success').toString());
      }
      if (status !== 200) {
        if (this.sms.detail === '') {
          toast(this.$bvToast, this.$t('settings.rides.messages.sms').toString(), ToastVariants.DANGER);
        } else {
          toast(this.$bvToast, this.$t('settings.rides.messages.error').toString(), ToastVariants.DANGER);
        }
      }
      this.isLoading = false;
    },
  },
  mounted() {
    const { query: { tab } } = this.$router.currentRoute;
    if (tab) this.activeTab = tab as TabName;
    if (this.getAllCars.length === 0) {
      this.fetchAllCars(this.getUserId);
    }
  },
});
