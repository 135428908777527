
import Vue from 'vue';
import VueSelect from 'vue-select';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';

export default Vue.extend({
  name: 'AtomDropdownSelect',
  extends: VueSelect,
  components: {
    VueSelect,
  },
  props: {
    value: {
      default: null,
    },
    info: {
      type: String,
      default: '',
    },
    isValid: {
      type: Boolean,
      default: true,
    },
    inputLabel: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: true,
    },
    optionalLabel: {
      type: String,
      default: 'dictionary.optional',
    },
  },
  data: () => ({
    selected: null,
    OpenIndicator: {
      render: (createElement) => createElement(
        AtomSvgIcon,
        {
          class: { 'vue-select-open-indicator': true },
          props: { imageSrc: 'icons/icon-chevron-right.svg' },
        },
      ),
    },
  }),
  mounted() {
    this.selected = this.$props.value;
  },
  watch: {
    value(value) {
      this.selected = value;
    },
  },
});
