
import Vue from 'vue';
import { mapGetters } from 'vuex';
import AtomButton, { ButtonVariant } from '@/components/atoms/AtomButton.vue';
import AtomActionCard from '@/components/atoms/AtomActionCard.vue';
import AtomNotification from '@/components/atoms/AtomNotification.vue';
import AtomSetup from '@/components/atoms/AtomSetup.vue';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';
import AtomSwitch from '@/components/atoms/AtomSwitch.vue';
import MoleculeRangeSlider from '@/components/molecules/range-slider/MoleculeRangeSlider.vue';
import OrganismAddCar from '@/components/organisms/car/OrganismAddCar.vue';

export default Vue.extend({
  name: 'OrganismRideSettingsDriver',
  components: {
    AtomButton,
    AtomActionCard,
    AtomNotification,
    AtomSetup,
    AtomSvgIcon,
    AtomSwitch,
    MoleculeRangeSlider,
    OrganismAddCar,
  },
  props: {
    rideSettings: {
      type: Object,
      required: true,
    },
    savingInProgress: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    ButtonVariant,
    settings: {
      minSharedDurationPercentage: 30,
      automaticShiftOnly: false,
      maxDetourMinutes: 15,
    },
  }),
  computed: {
    ...mapGetters('user', [
      'getUserId',
    ]),
    ...mapGetters('car', ['getAllCars']),
    dataHadChanged() {
      return (
        this.settings.minSharedDurationPercentage !== this.rideSettings.minSharedDurationPercentage
        || this.settings.automaticShiftOnly !== this.rideSettings.automaticShiftOnly
        || this.settings.maxDetourMinutes !== this.rideSettings.maxDetourMinutes
      );
    },
  },
  mounted() {
    this.settings = {
      ...this.settings,
      ...this.rideSettings,
    };
  },
  methods: {
    changeMinSharedDurationPercentage(value: number): void {
      this.settings.minSharedDurationPercentage = value;
    },
    getButtonVariant(isSelected: boolean): ButtonVariant {
      return isSelected ? ButtonVariant.PRIMARY : ButtonVariant.SECONDARY;
    },
    setDetour(value: number): void {
      this.settings.maxDetourMinutes = value;
    },
    sendDriverSettings(): void {
      this.$emit('sendDriverSettings', this.settings);
    },
    openAddCar(): void {
      this.$bvModal.show('tg-modal--add-car');
    },
  },
});
