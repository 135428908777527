var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"controls-wrapper"},[_c('div',[_c('AtomText',{attrs:{"tag":"h4"}},[_vm._v(" "+_vm._s(_vm.$t('settings.rides.rideTab.roleHeader'))+" ")]),_c('AtomText',{staticClass:"small",attrs:{"tag":"p","noMargin":""}},[_vm._v(" "+_vm._s(_vm.$t('settings.rides.rideTab.mediationText'))+" ")])],1),_c('div',{staticClass:"dropdown-select",staticStyle:{"min-width":"250px"}},[_c('AtomDropdownSelect',{attrs:{"icon":"","label":"text","inputLabel":_vm.$t('settings.rides.rideTab.genericDropdownLabel'),"options":_vm.genderOptionsCalc,"value":_vm.selectedOption},on:{"input":(o) => (_vm.settings.role = o.value)}})],1)]),_c('div',[(this.getAllCars.length === 0)?_c('AtomNotification',{staticClass:"call-to-action",attrs:{"big":""},on:{"click":_vm.openAddCar}},[_vm._v(" "+_vm._s(_vm.$t('settings.carNotification'))+" ")]):_vm._e(),(!_vm.hasPhoneNumber || !_vm.isPhoneVerified)?_c('AtomNotification',{attrs:{"big":""},on:{"click":_vm.openPhoneValidation}},[_vm._v(" "+_vm._s(_vm.hasPhoneNumber && !_vm.isPhoneVerified ? _vm.$t('global.alerts.verifyPhoneNumber') : _vm.$t('global.alerts.addPhoneNumber'))+" ")]):_vm._e()],1),_c('div',{staticClass:"divider"}),_c('div',{staticClass:"controls-wrapper"},[_c('div',[_c('AtomText',{attrs:{"tag":"h4"}},[_vm._v(" "+_vm._s(_vm.$t('settings.rides.rideTab.organizationHeader'))+" ")]),_c('AtomText',{staticClass:"small",attrs:{"tag":"p","noMargin":""}},[_vm._v(" "+_vm._s(_vm.$t('settings.rides.rideTab.organizationText'))+" ")])],1),_c('div',{staticClass:"dropdown-select",staticStyle:{"min-width":"250px"}},[_c('AtomDropdownSelect',{attrs:{"icon":"","label":"text","inputLabel":_vm.$t('settings.rides.rideTab.genericDropdownLabel'),"options":_vm.groups,"value":_vm.selectedGroup},on:{"input":_vm.setMatchingGroup}})],1)]),_c('div',{staticClass:"divider"}),(
      _vm.hasFeature(_vm.features, _vm.FeaturesEnum.BILLING_DONATION) &&
      _vm.hasPaymentTypeEnabled(_vm.PaymentTypesEnum.DONATION_PAYPAL)
    )?_c('div',[_c('AtomSetup',{attrs:{"content":_vm.$t('settings.rides.rideTab.paymentTitle'),"text":_vm.$t('settings.rides.rideTab.paymentDescription')},scopedSlots:_vm._u([{key:"description",fn:function(){return [(
            _vm.hasPaymentTypeEnabled(_vm.PaymentTypesEnum.DONATION_PAYPAL) &&
            !_vm.hasPaypalEmail &&
            _vm.claimsAndOffersPayment
          )?_c('AtomNotification',{attrs:{"big":""},on:{"click":function($event){return _vm.$bvModal.show(_vm.PaymentModalId)}}},[_vm._v(" "+_vm._s(_vm.$t('rideIntent.extras.payPalEmailNotification'))+" ")]):_vm._e()]},proxy:true}],null,false,2622053620)},[_c('AtomSwitch',{attrs:{"id":"default-payment-toggle","selected":_vm.claimsAndOffersPayment},on:{"change":(isChecked) => {
            this.settings.claimsPayment = isChecked;
            this.settings.offersPayment = isChecked;
          }}})],1)],1):_vm._e(),(false)?_c('div',{staticClass:"controls-wrapper"},[_c('div',{staticClass:"return-trip-text"},[_c('AtomText',{attrs:{"tag":"h4"}},[_vm._v(" "+_vm._s(_vm.$t('settings.rides.rideTab.returnTripHeader'))+" ")]),_c('AtomText',{staticClass:"small",attrs:{"tag":"p","noMargin":""}},[_vm._v(" "+_vm._s(_vm.$t('settings.rides.rideTab.returnTripText'))+" ")])],1),_c('AtomCheckbox',{attrs:{"id":"returnTrip","content":""}})],1):_vm._e(),(false)?_c('div',{staticClass:"divider"}):_vm._e(),(false)?_c('div',{staticClass:"controls-wrapper"},[_c('div',{staticClass:"d-inline-block"},[_c('AtomText',{attrs:{"tag":"h4"}},[_vm._v(" "+_vm._s(_vm.$t('settings.rides.rideTab.ratingHeader'))+" ")]),_c('AtomText',{staticClass:"small",attrs:{"tag":"p","noMargin":""}},[_vm._v(" "+_vm._s(_vm.$t('settings.rides.rideTab.ratingText'))+" ")])],1),_c('AtomCheckbox',{attrs:{"id":"rating","content":""}})],1):_vm._e(),(false)?_c('div',{staticClass:"divider"}):_vm._e(),_c('div',[_c('div',{staticClass:"save-btn float-right"},[_c('AtomButton',{attrs:{"variant":_vm.ButtonVariant.PRIMARY,"content":_vm.$t('dictionary.save'),"disabled":(_vm.validPhoneCar && !_vm.dataHadChanged) || !_vm.validPhoneCar,"is-loading":_vm.savingInProgress},on:{"click":_vm.updateSettings}})],1)]),_c('OrganismPayPalSetup'),_c('OrganismAddCar'),_c('OrganismPhoneValidation')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }