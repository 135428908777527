
import Vue from 'vue';
import AtomSetup from '@/components/atoms/AtomSetup.vue';
import AtomSwitch from '@/components/atoms/AtomSwitch.vue';
import AtomButton, { ButtonVariant } from '@/components/atoms/AtomButton.vue';

export default Vue.extend({
  name: 'OrganismRideSettingsPassenger',
  components: {
    AtomSetup,
    AtomButton,
    AtomSwitch,
  },
  props: {
    rideSettings: {
      type: Object,
      required: true,
    },
    savingInProgress: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    settings: {
      lightSequence: undefined,
    },
    ButtonVariant,
  }),
  mounted() {
    this.settings = {
      ...this.settings,
      ...this.rideSettings,
    };
  },
  computed: {
    dataHadChanged(): boolean {
      return (this.settings.lightSequence !== this.rideSettings.lightSequence);
    },
  },
  methods: {
    changeUserLightSequence() {
      this.$emit('changeLightSequence', this.settings);
    },
  },
});
