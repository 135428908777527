
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import AtomButton, { ButtonVariant } from '@/components/atoms/AtomButton.vue';
import AtomText from '@/components/atoms/AtomText.vue';
import AtomSetup from '@/components/atoms/AtomSetup.vue';
import AtomDropdownSelect from '@/components/atoms/AtomDropdownSelect.vue';
import AtomCheckbox from '@/components/atoms/AtomCheckbox.vue';
import AtomSwitch from '@/components/atoms/AtomSwitch.vue';
import AtomNotification from '@/components/atoms/AtomNotification.vue';
import OrganismPayPalSetup, {
  MODAL_ID as PaymentModalId,
} from '@/components/organisms/payment/OrganismPayPalSetup.vue';
import UserModel from '@/models/user/UserModel';
import { BvModal } from 'bootstrap-vue';
import { hasFeature } from '@/services/feature/FeatureService';
import PaymentTypesEnum from '@/enums/payment/PaymentTypesEnum';
import FeaturesEnum from '@/enums/settings/FeatureEnum';
import RolesEnum from '@/enums/rides/RolesEnum';
import { TranslateResult } from 'vue-i18n';
import user from '@/store/modules/user';
import userInfo from 'os';
import OrganismAddCar from '@/components/organisms/car/OrganismAddCar.vue';
import OrganismPhoneValidation, {
  PHONE_VALIDATION_MODAL_ID,
} from '@/components/organisms/phone-validation/OrganismPhoneValidation.vue';

export interface MatchingGroupModel {
  text: string;
  value: string;
}

export interface RideUpdateModel {
  role: RolesEnum;
  group: string;
}

export default Vue.extend({
  name: 'OrganismGeneralRideSettings',
  components: {
    AtomButton,
    AtomText,
    AtomDropdownSelect,
    AtomCheckbox,
    AtomSwitch,
    AtomSetup,
    AtomNotification,
    OrganismPayPalSetup,
    OrganismAddCar,
    OrganismPhoneValidation,
  },
  props: {
    savingInProgress: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      phone: undefined as string | undefined,
      ButtonVariant,
      RolesEnum,
      PaymentTypesEnum,
      FeaturesEnum,
      PaymentModalId,
      companyShy: false,
      dropDownMatchingGroup: [] as Array<MatchingGroupModel>,
      settings: {
        matchingGroups: [] as string[],
        role: RolesEnum.DRIVER_AND_PASSENGER,
        claimsPayment: false,
        offersPayment: false,
      } as UserModel['rideSettings'],
      genderOptions: [
        { text: 'rideIntent.role.driver', value: RolesEnum.DRIVER },
        { text: 'rideIntent.role.passenger', value: RolesEnum.PASSENGER },
        {
          text: 'rideIntent.role.driverOrPassenger',
          value: RolesEnum.DRIVER_AND_PASSENGER,
        },
      ],
    };
  },
  methods: {
    ...mapActions('user', ['fetchUser']),
    hasFeature,
    updateSettings() {
      this.$emit('changeGeneralSettings', this.settings);
    },
    setMatchingGroup(group) {
      this.settings.matchingGroups = group.value === '' ? [] : [group.value];
    },
    mapSettings() {
      this.companyShy = this.site === undefined ? true : this.site.shy;
      this.settings.offersPayment = this.rideSettings.offersPayment;
      this.settings.claimsPayment = this.rideSettings.claimsPayment;
      this.settings.role = this.rideSettings.role;
    },
    openAddCar(): void {
      this.$bvModal.show('tg-modal--add-car');
    },
    openPhoneValidation() {
      this.$bvModal.show(PHONE_VALIDATION_MODAL_ID);
    },
  },
  computed: {
    ...mapGetters('user', [
      'getUserId',
      'rideSettings',
      'availableMatchingGroups',
      'b2cFree',
      'site',
      'features',
      'hasPaymentTypeEnabled',
      'hasPaypalEmail',
      'hasPhoneNumber',
      'isPhoneVerified',
    ]),
    ...mapGetters('car', ['getAllCars']),
    claimsAndOffersPayment(): boolean {
      return this.settings.offersPayment && this.settings.claimsPayment;
    },
    hideMatchingGroups() {
      if (this.b2cFree || this.companyShy || this.groups.length <= 1) {
        return false;
      }
      return true;
    },
    dataHadChanged(): boolean {
      return (
        this.settings.role !== this.rideSettings.role ||
        this.settings.matchingGroups.join('') !== this.rideSettings.matchingGroups.join('') ||
        this.rideSettings.offersPayment !== this.settings.offersPayment ||
        this.rideSettings.claimsPayment !== this.settings.claimsPayment
      );
    },
    validPhoneCar(): boolean {
      return this.getAllCars.length !== 0 && this.hasPhoneNumber && this.isPhoneVerified;
    },
    selectedOption(): {
      text: string | TranslateResult;
      value: RolesEnum;
    } | null {
      const option = this.genderOptions.find((o) => o.value === this.settings.role);
      return option ? { ...option, text: this.$t(option.text) } : null;
    },
    selectedGroup(): MatchingGroupModel | undefined {
      if (this.settings.matchingGroups.length > 0) {
        return this.groups.find((g) => g.value === this.settings.matchingGroups[0]);
      }
      return this.groups[0];
    },
    groups(): MatchingGroupModel[] {
      const groups = this.availableMatchingGroups;
      const options = [] as MatchingGroupModel[];

      Object.keys(groups).forEach((key) => {
        options.push({
          text: groups[key],
          value: key,
        });
      });

      return [
        { text: this.$t('settings.rides.rideTab.matchEveryone').toString(), value: '' },
        ...options,
      ];
    },
    genderOptionsCalc(): any {
      const allOptions = this.genderOptions.map((o) => ({ ...o, text: this.$t(o.text) }));
      return this.getAllCars.length === 0 || !this.hasPhoneNumber || !this.isPhoneVerified
        ? allOptions.filter((x) => x.value === 'passenger')
        : allOptions;
    },
  },
  async mounted() {
    this.mapSettings();
    this.settings = {
      ...this.settings,
      ...this.rideSettings,
    };
  },
  watch: {
    rideSettings(rideSettings) {
      this.settings = {
        ...this.settings,
        ...rideSettings,
      };
    },
  },
});
