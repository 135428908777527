
import Vue, { PropType } from 'vue';
import { BButton } from 'bootstrap-vue';
import AtomText from '@/components/atoms/AtomText.vue';
import { ButtonVariant } from '@/components/atoms/AtomButton.vue';

export default Vue.extend({
  name: 'AtomActionCard',
  data: () => ({
    ButtonVariant,
  }),
  components: {
    AtomText,
    BButton,
  },
  props: {
    content: {
      type: String,
      default: '',
    },
    variant: {
      type: String as PropType<ButtonVariant>,
      validator: (val: ButtonVariant) => Object.values(ButtonVariant).includes(val),
      default: ButtonVariant.PRIMARY,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
  },
  watch: {
    selected: 'changeSelectedState',
  },
});
